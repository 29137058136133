import React from 'react';
import useStyles from './Imprint.styles';
import Typography from '../../components/atoms/Typography/Typography';
import { localText, localValues } from './Imprint.static';
import Link from '../../components/atoms/Link/Link';

const emailLink = `mailto:${localValues.email}`;
const faxLink = `fax:${localValues.fax}`;
const telLink = `tel:${localValues.fax}`;

const Imprint: React.FC = () => {
  const classes = useStyles();

  return (
    <div className={classes.imprintRoot}>
      <Typography variant={'headline2'} className={classes.mainTitle}>
        {localText.imprint}
      </Typography>
      <Typography className={classes.section}>
        {localText.maintainedBy} <br />
        <br />
        {localText.contact} <Link url={emailLink}>{localValues.email}</Link>
        <br />
        <br />
        {localText.phone} <Link url={telLink}>{localValues.phone}</Link> <br />
        <br />
        {localText.workingTime} <br />
        <br />
        {localText.fax} <Link url={faxLink}>{localValues.fax}</Link>
        <br />
        <br />
        {localText.legalRepresentatives} <br />
        <br />
        {localText.supervisoryBoardChairman} <br />
        <br />
        {localText.commercialRegister} <br />
        <br />
        {localText.valueAddedTaxIndetification} <br />
        <br />
        {localText.emailContact} <Link url={emailLink}>{localValues.email}</Link>
        <br />
        <br />
      </Typography>
    </div>
  );
};

export default Imprint;
