import React from 'react';
import useStyles from './Footer.styles';
import Logo from '../../atoms/Logo/Logo';
import Link from '../../atoms/Link/Link';
import Typography from '../../atoms/Typography/Typography';
import { routes } from '../../../constants/routing';

export const localText = {
  copyright: `${new Date().getFullYear()} © BMW Group`,
  cookies: 'Cookies',
  imprint: 'Imprint',
};

const Footer: React.FC = () => {
  const classes = useStyles();

  return (
    <section className={classes.footerRoot}>
      <Logo hideTextOnMobile={false} variant={'small'} />
      <div className={classes.footerItems}>
        <Link url={routes.imprint.path ?? ''}>
          <Typography variant={'bodySmaller'}>{localText.imprint}</Typography>
        </Link>
        <Link url={routes.cookies.path ?? ''}>
          <Typography variant={'bodySmaller'}>{localText.cookies}</Typography>
        </Link>
        <Typography variant={'bodySmaller'}>{localText.copyright}</Typography>
      </div>
    </section>
  );
};

export default Footer;
