import React from 'react';
import Cookies from '../pages/Cookies/Cookies';
import Imprint from '../pages/Imprint/Imprint';
import Index from '../pages/Index/Index';
import { PathRouteProps } from 'react-router-dom';

type RouteKey = 'index' | 'cookies' | 'imprint';

type RoutesType = {
  [key in RouteKey]: PathRouteProps;
};

export const routes: RoutesType = {
  index: {
    path: '/',
    element: <Index />,
  },
  cookies: {
    path: '/cookies',
    element: <Cookies />,
  },
  imprint: {
    path: '/imprint',
    element: <Imprint />,
  },
};
