import { createStyles } from '@material-ui/core/styles';
import makeStyles from '../../util/makeStyles';
import { createRoot, sections } from '../../styles/shared';

export default makeStyles(() =>
  createStyles({
    cookiesRoot: createRoot(),
    ...sections,
  })
);
