import makeStyles from '../../../util/makeStyles';
import { createStyles } from '@material-ui/core/styles';
import portalTheme from '../../../styles/theme';

export default makeStyles(() =>
  createStyles({
    topbarMask: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundColor: portalTheme.colors.globalBackgroundLight,
      zIndex: -1,
    },

    topbarRoot: {
      width: '100%',
      paddingTop: 24,
      paddingBottom: 24,
      position: 'sticky',
      top: 0,
      left: 0,
      zIndex: portalTheme.zIndex.topbar,
      [portalTheme.breakpoints.down('xs')]: {
        paddingTop: 20,
        paddingBottom: 20,
      },
    },

    topbarWithShadow: {
      boxShadow: portalTheme.shadows.medium,
    },

    topbarInner: {
      ...portalTheme.sideMargins,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      height: '100%',
    },

    rightSection: {
      display: 'flex',
      alignItems: 'center',
    },

    soonOnGithub: {
      marginRight: 24,
    },
  })
);
