import makeStyles from '../../../util/makeStyles';
import { createStyles } from '@material-ui/core/styles';
import portalTheme from '../../../styles/theme';
import { createRoot } from '../../../styles/shared';

export default makeStyles(() =>
  createStyles({
    footerRoot: createRoot({
      marginTop: 160,
      paddingBottom: 80,
      display: 'grid',
      placeItems: 'center',
      gridTemplateRows: 'auto auto',
      gridTemplateColumns: '1fr',
      gridRowGap: 16,
      [portalTheme.breakpoints.down('sm')]: {
        marginTop: 80,
        paddingBottom: 84, // To make space for back to top button
      },
    }),

    footerItems: {
      display: 'grid',
      placeItems: 'center',
      gridTemplateColumns: 'auto auto auto',
      gridTemplateRows: '1fr',
      gridColumnGap: 32,
    },
  })
);
