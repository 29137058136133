import React, { forwardRef } from 'react';
import useStyles from './Typography.styles';
import classNames from 'classnames';
import portalTheme, { FontVariant } from '../../../styles/theme';

export const defaultVariant: FontVariant = 'body';
// We do not use h1, h2, h3 because we use the different levels solely for their different looks.
// Using h1, h2, h3 appropriately would require more work (e.g. make sure that we don't skip levels).
// We do not use <p> because we rarely render paragraphs of text.
export const defaultComponent = 'div';

export interface Props {
  bold?: boolean;
  children: React.ReactNode;
  className?: string;
  color?: string;
  component?: React.ElementType;
  hoverVariant?: 'default' | 'dark';
  noWrap?: boolean;
  numberOfLines?: number;
  onClick?: () => void;
  variant?: FontVariant;
  overflowEllipsis?: boolean;
  transition?: string;
}

/**
 * Use this component instead of Material UI's Typography to ensure you only use fonts supported by our styleguide.
 * If you need to use a font that does not appear in the styleguide, feel free to use Material UI's Typography.
 */
const Typography = forwardRef<HTMLDivElement, Props>(
  (
    {
      component = defaultComponent,
      className,
      children,
      color,
      variant = defaultVariant,
      bold,
      noWrap,
      hoverVariant,
      numberOfLines,
      overflowEllipsis,
      transition,
      ...other
    },
    ref
  ) => {
    const classes = useStyles();

    const Component = component;
    const defaultTextColor = variant === 'button' ? portalTheme.textColors.highlight : portalTheme.textColors.primary;
    const styleOverrides = {
      transition,
      color: color || defaultTextColor,
      fontWeight: bold ? 'bold' : undefined,
      WebkitLineClamp: numberOfLines?.toString(),
    };

    return (
      <Component
        className={classNames(className, classes[variant], {
          [classes.noWrap]: noWrap,
          [classes.hoverDefault]: hoverVariant === 'default',
          [classes.hoverDark]: hoverVariant === 'dark',
          [classes.clip]: numberOfLines,
          [classes.overflowEllipsis]: overflowEllipsis,
        })}
        style={styleOverrides}
        ref={ref}
        {...other}
      >
        {children}
      </Component>
    );
  }
);

export default Typography;
