import { Link as MuiLink } from '@material-ui/core';
import classNames from 'classnames';
import React, { forwardRef } from 'react';
import Tooltip, { TooltipPlacement } from '../Tooltip/Tooltip';
import useStyles from './Link.style';
import { Link as RouterLink } from 'react-router-dom';

export type Props = {
  url: string;
  children: React.ReactNode;
  className?: string;
  fullWidth?: boolean;
  openInNewTab?: boolean;
  onClick?: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
  tooltip?: string;
  tooltipPlacement?: TooltipPlacement;
  style?: React.CSSProperties;
};

const Link = forwardRef<HTMLAnchorElement, Props>(
  ({ url, children, fullWidth, className, openInNewTab = true, onClick, tooltip, tooltipPlacement, ...other }, ref) => {
    const classes = useStyles();
    const rootClassName = classNames(classes.root, fullWidth && classes.fullWidth);

    const isInnerLink = url.startsWith('/');

    return (
      <Tooltip text={tooltip} className={className} placement={tooltipPlacement}>
        {isInnerLink ? renderInnerLink() : renderOutsideLink()}
      </Tooltip>
    );

    function renderOutsideLink() {
      return (
        <MuiLink
          ref={ref}
          href={url}
          className={rootClassName}
          target={openInNewTab ? '_blank' : undefined}
          rel='noreferrer noopener'
          onClick={onClick}
          {...other}
        >
          {children}
        </MuiLink>
      );
    }

    function renderInnerLink() {
      return (
        <RouterLink ref={ref} to={url} className={rootClassName} rel='noreferrer noopener' onClick={onClick}>
          {children}
        </RouterLink>
      );
    }
  }
);

export default Link;
