import React from 'react';
import useStyles, { logoHeightNormal, logoHeightSmall, textWidthNormal, textWidthSmall } from './Logo.styles';
import Icon from '../../atoms/Icon/Icon';
import icons from '../../../statics/icons';
import portalTheme from '../../../styles/theme';
import { ScreenSize, useIfScreenSizeAtMost } from '../../../hooks/mediaHooks';
import classNames from 'classnames';
import { routes } from '../../../constants/routing';
import Link from '../Link/Link';

export interface Props {
  variant?: 'normal' | 'small';
  hideTextOnMobile?: boolean;
  className?: string;
}

const Logo: React.FC<Props> = ({ variant = 'normal', hideTextOnMobile = false, className }) => {
  const classes = useStyles();
  const isMobile = useIfScreenSizeAtMost(ScreenSize.xs);

  const logoHeight = variant === 'normal' ? logoHeightNormal : logoHeightSmall;
  const logoWidth = variant === 'normal' ? textWidthNormal : textWidthSmall;

  return (
    <Link url={routes.index.path ?? ''}>
      <div
        className={classNames(
          classes.logoContainer,
          variant === 'small' && classes.logoContainerSmall,
          hideTextOnMobile && isMobile && classes.textOnly,
          className
        )}
      >
        <Icon color={portalTheme.colors.primary} size={logoHeight} pathProps={icons.cdhLogo} />
        {(!isMobile || !hideTextOnMobile) && (
          <Icon
            color={portalTheme.textColors.primary}
            size={{ height: logoHeight, width: logoWidth }}
            pathProps={icons.cdhLogoText}
          />
        )}
      </div>
    </Link>
  );
};

export default Logo;
