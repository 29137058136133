import { createStyles } from '@material-ui/core/styles';
import makeStyles from '../../../util/makeStyles';
import portalTheme from '../../../styles/theme';

export default makeStyles(() =>
  createStyles({
    tooltip: {
      backgroundColor: portalTheme.colors.dark,
      borderRadius: '4px',
      padding: '8px 12px',
      color: 'white',
    },
  })
);
