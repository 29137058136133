import React from 'react';
import Button from '../../atoms/Button/Button';
import { CDH_EMAIL } from '../../../constants/constants';

export const localText = {
  moreDetails: 'Contact Us',
};

const href = `mailto:${CDH_EMAIL}`;

const RequestMoreDetails: React.FC<{ className?: string }> = ({ className }) => {
  return <Button url={href} className={className} title={localText.moreDetails} variant={'primary'} />;
};

export default RequestMoreDetails;
