import { useMediaQuery, useTheme } from '@material-ui/core';

export enum ScreenSize {
  xs = 'XS',
  sm = 'SM',
  md = 'MD',
  lg = 'LG',
  xl = 'XL',
}

const screenSizeToBreakpoint: Record<ScreenSize, 'xs' | 'sm' | 'md' | 'lg' | 'xl'> = {
  [ScreenSize.xs]: 'xs',
  [ScreenSize.sm]: 'sm',
  [ScreenSize.md]: 'md',
  [ScreenSize.lg]: 'lg',
  [ScreenSize.xl]: 'xl',
};

export function useIfScreenSizeAtMost(size: ScreenSize) {
  const theme = useTheme();

  return useMediaQuery(theme.breakpoints.down(screenSizeToBreakpoint[size]));
}

export default {
  useIfScreenSizeAtMost,
};
