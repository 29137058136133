import React from 'react';
import Intro from '../../components/parts/Intro/Intro';
import About from '../../components/parts/About/About';

const Index: React.FC = () => (
  <>
    <Intro />
    <About />
  </>
);

export default Index;
