import makeStyles from '../../../util/makeStyles';
import { createStyles } from '@material-ui/core/styles';
import portalTheme from '../../../styles/theme';
import { createRoot } from '../../../styles/shared';

export default makeStyles(() =>
  createStyles({
    introRoot: createRoot(),

    bigTeaserText: {
      marginTop: 24,
      maxWidth: 865,
    },

    ctaContainer: {
      marginTop: 40,
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      [portalTheme.breakpoints.down('sm')]: {
        flexWrap: 'wrap',
      },
    },

    requestMoreDetails: {
      marginRight: 24,
    },
  })
);
