import React from 'react';
import { Tooltip as MuiTooltip, TooltipProps } from '@material-ui/core';
import useStyles from './Tooltip.style';

export type TooltipPlacement = TooltipProps['placement'];

interface Props {
  text: string | undefined;
  children: React.ReactElement;
  className?: string;
  placement?: TooltipPlacement;
}

const Tooltip: React.FC<Props> = ({ text, children, placement = 'top', className }) => {
  const classes = useStyles();

  return (
    <MuiTooltip
      title={text || ''}
      className={className}
      classes={{ tooltip: classes.tooltip }}
      placement={placement}
      enterDelay={200}
    >
      {children}
    </MuiTooltip>
  );
};

export default Tooltip;
