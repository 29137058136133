import { TypographyStyleOptions } from '@material-ui/core/styles/createTypography';
import createBreakpoints from '@material-ui/core/styles/createBreakpoints';
import { withOpacity } from '../util/utilities';

// createBreakpoints will add media query functions like 'up', 'down', and 'between'
const breakpoints = createBreakpoints({
  keys: ['xs', 'sm', 'md', 'lg', 'xl'],
  values: {
    xs: 0, // xs should always be set to 0, or e.g. breakpoints.down('xl') does not work correctly
    sm: 640,
    md: 960,
    lg: 1280,
    xl: 1920,
  },
});

export type FontVariant = 'headline1' | 'headline2' | 'body' | 'button' | 'bodySmaller';

const pxToRem = (value: number) => `${value / 16}rem`;
const fonts: Record<FontVariant, TypographyStyleOptions> = {
  headline1: {
    fontWeight: 'bold',
    fontSize: pxToRem(48),
    letterSpacing: 0,
    lineHeight: pxToRem(64),
    [breakpoints.down('sm')]: {
      fontSize: pxToRem(32),
      lineHeight: pxToRem(48),
    },
  },
  headline2: {
    fontWeight: 'bold',
    fontSize: pxToRem(32),
    letterSpacing: 0,
    lineHeight: pxToRem(48),
    [breakpoints.down('sm')]: {
      fontSize: pxToRem(24),
      lineHeight: pxToRem(32),
    },
  },
  body: {
    fontWeight: 'normal',
    fontSize: pxToRem(16),
    letterSpacing: pxToRem(0.3),
    lineHeight: pxToRem(28),
  },
  bodySmaller: {
    fontWeight: 'normal',
    fontSize: pxToRem(12),
    letterSpacing: pxToRem(0.3),
    lineHeight: pxToRem(20),
  },
  button: {
    fontWeight: 'bold',
    fontSize: pxToRem(10),
    letterSpacing: pxToRem(0.08),
    lineHeight: pxToRem(14),
  },
};

/**
 * Use this color for transparent white gradients.
 * `transparent` is interpreted differently by Safari, which causes design bugs (at least Safari 14.0 and earlier).
 * See also:
 * - https://developer.mozilla.org/en-US/docs/Web/CSS/color_value#transparent_keyword
 * - https://www.w3.org/TR/css-color-4/#valdef-color-transparent
 */
const transparentWhite = 'rgba(255,255,255,0)';

function makeShadow(offsetY: number, blurRadius: number, opacity: number) {
  return `0 ${offsetY}px ${blurRadius}px 0 rgba(0, 0, 0, ${opacity})`;
}

// Shadows from the Style Guide
// https://share.goabstract.com/a0a19b01-e748-4fbb-89c8-e29cfe5e022e?collectionLayerId=9771969e-0215-4a3b-9382-821c466d18b6&mode=build
const shadows = {
  medium: makeShadow(5, 10, 0.05),
  strong: makeShadow(10, 20, 0.1),
  insetTop: 'inset ' + makeShadow(5, 10, 0.05),
  insetBottom: 'inset ' + makeShadow(-5, 10, 0.05),
};

const transitions = {
  easeOutFast: 'all 0.2s ease-out',
  easeOutSlow: 'all 0.4s ease-out',
};

const innerContentSideSpacing = 32;
const spaceBetweenGridColumns = 24;

const globalPaddingTablet = 64; // padding around the whole page
const globalPaddingMobile = 24; // padding around the whole page

const createSideMargins = (skipColumns = 0) => {
  const createPadding = (isLeft = false) =>
    `calc(((100% / 12) * ${skipColumns}) - ${isLeft ? spaceBetweenGridColumns : 0}px)`;

  return {
    marginLeft: 'auto',
    marginRight: 'auto',
    width: `calc(100% - ${innerContentSideSpacing * 2}px)`,
    paddingLeft: skipColumns && createPadding(true),
    paddingRight: skipColumns && createPadding(),
    maxWidth: 1280,
    [breakpoints.down('sm')]: {
      paddingLeft: globalPaddingTablet,
      paddingRight: globalPaddingTablet,
      width: '100%',
    },
    [breakpoints.down('xs')]: {
      paddingLeft: globalPaddingMobile,
      paddingRight: globalPaddingMobile,
    },
  };
};

const sideMargins = createSideMargins();
const sideMarginsSkipOneCol = createSideMargins(1);
const scrollBarPadding = 10;

const zIndex = {
  topbar: 1000,
};

const portalTheme = {
  zIndex,
  globalPaddingTablet,
  scrollBarPadding,
  spaceBetweenGridColumns,
  innerContentSideSpacing,
  sideMargins,
  sideMarginsSkipOneCol,
  sidebarWidth: 200,
  sidebarWidthCollapsed: 80,
  fonts,
  shadows,
  breakpoints,
  transitions,
  fontFamily: '"BMW-Next", "Helvetica", "Arial", sans-serif',
  menuItemHeight: 40,
  colors: {
    primary: '#1b67d0',
    primaryDark: '#0653B6',
    primaryLight: '#d1e1f6',
    secondary: '#ff7600',
    secondaryLight: '#FFE9D6',
    secondaryDark: '#E56E00',
    tertiary: '#288271',
    tertiaryLight: '#E9F2F0',
    dark: '#2a2b38',
    globalBackgroundLight: '#f8f9fd',
    globalBackgroundDark: '#dcdddd',
    grayShadeMedium: '#AAAAAF',
    grayIcon: '#979797',
    grayShadeDark: '#288271',
    error: '#EA0849',
    errorDark: '#bb063a',
    transparentWhite,
    backdrop: 'rgba(0,0,0,0.4)',
  },
  textColors: {
    primary: '#2a2b38',
    primaryLighter: withOpacity('#2a2b38', 0.7),
    light: '#696a73',
    highlight: '#1b67d0',
    error: '#ea0849',
  },
  borders: {
    primary: `1px solid ${withOpacity('#2a2b38', 0.08)}`,
    highlight: '1px solid #1b67d0',
  },
  divider: `1px solid ${withOpacity('#2a2b38', 0.08)}`,
};

export default portalTheme;
