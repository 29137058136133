import React, { forwardRef } from 'react';
import useStyles from './Icon.style';
import classNames from 'classnames';
import { SvgIcon } from '@material-ui/core';
import { PathProps } from '../../../statics/icons';

export const defaultIconSize = 24;

export type IconHoverVariant = 'default' | 'primaryDark';

export interface IconSize {
  height: number;
  width: number;
}

export interface Props {
  className?: string;
  color: string;
  hoverVariant?: IconHoverVariant;
  onClick?: (() => void) | ((event: React.MouseEvent<SVGSVGElement, MouseEvent>) => void);
  onMouseDown?: (() => void) | ((event: React.MouseEvent<SVGSVGElement, MouseEvent>) => void);
  onMouseEnter?: (() => void) | ((event: React.MouseEvent<SVGSVGElement, MouseEvent>) => void);
  onMouseLeave?: (() => void) | ((event: React.MouseEvent<SVGSVGElement, MouseEvent>) => void);
  pathProps: PathProps;
  size?: number | IconSize;
  transition?: string;
  fill?: {
    path: string;
    color?: string;
  };
}

const Icon = forwardRef<SVGSVGElement, Props>(
  (
    {
      className,
      color,
      fill,
      hoverVariant,
      onClick,
      onMouseDown,
      onMouseEnter,
      onMouseLeave,
      pathProps,
      transition,
      size = defaultIconSize,
      ...rest
    },
    ref
  ) => {
    const classes = useStyles();

    function getHoverClass() {
      switch (hoverVariant) {
        case 'default':
          return classes.hoverDefault;
        case 'primaryDark':
          return classes.hoverPrimaryDark;
        default:
          return undefined;
      }
    }

    const styleOverride = {
      transition,
      width: typeof size === 'number' ? size : size.width,
      height: typeof size === 'number' ? size : size.height,
    };

    return (
      <SvgIcon
        viewBox={pathProps.viewBox}
        ref={ref}
        style={styleOverride}
        className={classNames(className, classes.root)}
        onClick={onClick}
        onMouseDown={onMouseDown}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        {...rest}
      >
        {fill && <path d={fill.path} fill={fill.color || color} />}
        <path d={pathProps.path} fill={color} transform={pathProps.transform} className={getHoverClass()} />
        {pathProps.secondPath && <path d={pathProps.secondPath} fill={color} />}
      </SvgIcon>
    );
  }
);

export default Icon;
