import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

export const useScrollYDistance = (): number => {
  const [state, setState] = useState<number>(window.pageYOffset);

  useEffect(() => {
    const setter = () => {
      setState(window.scrollY);
    };

    window.addEventListener('scroll', setter);
    return () => window.removeEventListener('scroll', setter);
  }, []);

  return state;
};

export function useScrollToTopOnRouteChange() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
}

export default {
  useScrollYDistance,
};
