import React from 'react';
import Typography from '../../atoms/Typography/Typography';
import RequestMoreDetails from '../../molecules/RequestMoreDetails/RequestMoreDetails';
import useStyles from './Intro.styles';

export const localText = {
  cdh: 'Cloud Data Hub',
  bigTeaserText: 'Organize all of BMW Group’s data and make it universally actionable.',
};

const Intro: React.FC = () => {
  const classes = useStyles();

  return (
    <section className={classes.introRoot}>
      <Typography>{localText.cdh}</Typography>
      <Typography variant={'headline1'} className={classes.bigTeaserText}>
        {localText.bigTeaserText}
      </Typography>
      {renderCTAs()}
    </section>
  );

  function renderCTAs() {
    return (
      <div className={classes.ctaContainer}>
        <RequestMoreDetails className={classes.requestMoreDetails} />
      </div>
    );
  }
};

export default Intro;
