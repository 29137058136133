import { createStyles } from '@material-ui/core/styles';
import portalTheme from '../../../styles/theme';
import makeStyles from '../../../util/makeStyles';

export default makeStyles(() =>
  createStyles({
    ...portalTheme.fonts,

    noWrap: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },

    hoverDefault: {
      '.hoverable:hover &': {
        color: `${portalTheme.textColors.highlight} !important`,
      },
    },

    hoverDark: {
      '.hoverable:hover &': {
        color: `${portalTheme.colors.primaryDark} !important`,
      },
    },

    clip: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: '-webkit-box',
      boxOrient: 'vertical',
      overflowWrap: 'anywhere',
    },

    overflowEllipsis: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  })
);
