import React from 'react';
import { CircularProgress as MuiCircularProgress } from '@material-ui/core';
import useStyles from './CircularProgress.style';
import portalTheme from '../../../styles/theme';

export const CircularProgress: React.FC<Props> = ({ className, size, color }) => {
  const classes = useStyles();
  const styleOverrides = {
    color: color || portalTheme.textColors.primary,
  };

  return (
    <MuiCircularProgress
      size={size}
      style={styleOverrides}
      classes={{ circle: classes.circle }}
      className={className}
      thickness={5}
    />
  );
};

interface Props {
  className?: string;
  color?: string;
  size: number;
}

export default CircularProgress;
