import { createStyles } from '@material-ui/core';
import makeStyles from '../../../util/makeStyles';

export default makeStyles(() =>
  createStyles({
    root: {
      color: 'inherit',
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'none',
      },
    },

    fullWidth: {
      display: 'block',
      width: '100%',
    },
  })
);
