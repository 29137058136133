import React from 'react';
import Scroll from 'react-scroll';
import icons from '../../../statics/icons';
import { ActionButton } from '../../atoms/ActionButton/ActionButton';

export const localText = {
  backToTop: 'Back To Top',
};

export interface Props {
  className?: string;
}

export const BackToTopButton: React.FC<Props> = ({ className }) => {
  return (
    <ActionButton
      onClick={Scroll.animateScroll.scrollToTop}
      iconPath={icons.arrowUp}
      name={localText.backToTop}
      className={className}
    />
  );
};

export default BackToTopButton;
