const whatIsACookieExplained = [
  'A cookie is a small file that stores Internet settings. Almost every website uses cookie technology. It is downloaded by your Internet browser on the first visit to a website. The next time this website is opened with the same user device, the cookie and the information stored in it is either sent back to the website that created it (first-party cookie) or sent to another website it belongs to (third-party cookie). This enables the website to detect that you have opened it previously with this browser and in some cases to vary the displayed content.',
  'Some cookies are extremely useful, as they can improve the user experience on opening a website that you have already visited a number of times. Provided you use the same user device and the same browser as before, cookies remember for example your preferences, how you use a site, and adapt the displayed offerings to be more relevant to your personal interest and needs.',
  'Depending on their function and intended purpose, cookies can be divided into four categories*: essential cookies, performance cookies, functional cookies, cookies for marketing purposes.',
];

export const localText = {
  cookies: 'Cookies',
  cookieSettings: 'Cookies settings',
  cookieUsage: 'This website does not use cookies.',
  whatIsACookie: 'What is a Cookie?',
  whatIsACookieExplained,
};
