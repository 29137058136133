import React, { ElementType } from 'react';
import { PathProps } from '../../../statics/icons';
import { withOpacity } from '../../../util/utilities';
import { CircularProgress } from '../CircularProgress/CircularProgress';
import Tooltip from '../Tooltip/Tooltip';
import useStyles, { iconSize } from './Button.style';
import { Button as MuiButton, SvgIcon } from '@material-ui/core';
import classNames from 'classnames';
import Link from '../Link/Link';
import { exhaustivenessCheck } from '../../../util/misc';
import portalTheme from '../../../styles/theme';

export type ButtonVariant = 'primary' | 'secondary' | 'text';

export interface Props {
  title: string;
  variant: ButtonVariant;
  onClick?: () => void;
  icon?: PathProps;
  isLoading?: boolean;
  className?: string;
  isDisabled?: boolean;
  width?: number;
  url?: string;
  color?: 'primary' | 'alert';
  tooltip?: string;
  component?: ElementType;
  openExternalLinkInNewTab?: boolean;
}

const Button: React.FC<Props> = ({
  onClick,
  icon,
  title,
  className,
  isDisabled,
  width,
  variant,
  isLoading,
  color = 'primary',
  url,
  tooltip,
  component = 'button',
  openExternalLinkInNewTab,
}) => {
  const classes = useStyles();
  const showsIcon = !!icon || isLoading;

  function getVariantStyle() {
    switch (variant) {
      case 'primary':
        return classes.primaryVariant;
      case 'secondary':
        return classes.secondaryVariant;
      case 'text':
        return classes.textVariant;
      default:
        return exhaustivenessCheck(variant);
    }
  }

  function getColor() {
    const color = variant === 'primary' ? '#ffffff' : portalTheme.colors.primary;

    return isDisabled || isLoading ? withOpacity(color, 0.4) : color;
  }

  return (
    <Tooltip text={tooltip} className={className}>
      {url ? (
        <Link url={url} openInNewTab={openExternalLinkInNewTab} className={classes.buttonWrapper} style={{ width }}>
          {renderBase()}
        </Link>
      ) : (
        <div className={classes.buttonWrapper} style={{ width }}>
          {renderBase()}
        </div>
      )}
    </Tooltip>
  );

  function renderBase() {
    return (
      <>
        {/* A disabled button does not send mouse events. To make the tooltip work, we need this div. */}
        <MuiButton
          disableRipple
          onClick={onClick}
          className={classNames(
            classes.root,
            getVariantStyle(),
            showsIcon && classes.withIcon,
            color === 'alert' && classes.alertColor
          )}
          disabled={isDisabled || isLoading}
          style={{ color: getColor() }}
          variant={variant === 'text' ? 'text' : undefined}
          component={component}
        >
          {isLoading ? renderSpinner() : renderIcon()}
          {title}
        </MuiButton>
      </>
    );
  }

  function renderSpinner() {
    return <CircularProgress className={classes.icon} color={getColor()} size={iconSize} />;
  }

  function renderIcon() {
    return (
      icon && (
        <SvgIcon viewBox={icon.viewBox} className={classes.icon}>
          <path d={icon.path} fill={getColor()} transform={icon.transform} />
          {icon.secondPath && <path d={icon.secondPath} fill={getColor()} />}
        </SvgIcon>
      )
    );
  }
};

export default Button;
