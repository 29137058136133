// eslint-disable-next-line no-restricted-imports
import { makeStyles as muiMakeStyles } from '@material-ui/core/styles';

// We must use our own version of makeStyles to ensure our styles are loaded *after* Material UI's default styles.
// Warning: When this function was added, only the production build suffered from broken styling.
const makeStyles: typeof muiMakeStyles = (f) => {
  return muiMakeStyles(f, { index: 1 });
};

export default makeStyles;
