import { CreateCSSProperties } from '@material-ui/core/styles/withStyles';
import { merge } from 'lodash';
import portalTheme from './theme';

const sectionTextsMaxWidth = 760;
const sections = {
  mainTitle: {
    marginBottom: 32,
    maxWidth: sectionTextsMaxWidth,
  },

  heading: {
    marginBottom: 24,
    maxWidth: sectionTextsMaxWidth,
  },

  section: {
    marginBottom: 40,
    maxWidth: sectionTextsMaxWidth,
  },
};

function createRoot(styles: CreateCSSProperties = {}) {
  return merge(
    {
      marginTop: 80,
      [portalTheme.breakpoints.down('sm')]: {
        marginTop: 48,
      },
    },
    portalTheme.sideMargins,
    styles
  );
}

export { sections, createRoot };
