import React from 'react';
import useStyles from './About.styles';
import Typography from '../../atoms/Typography/Typography';
import infographic from '../../../statics/infographic.svg';
import InfoBlock, { Block } from '../../molecules/InfoBlock/InfoBlock';
import icons from '../../../statics/icons';

const localText = {
  howItWorks: 'How It Works',
};

const infoBlocks: Block[] = [
  {
    title: 'Accessible Data Lake',
    description: 'Built upon AWS, the CDH offers an intuitive portal, APIs and data spaces.',
    icon: icons.rocket,
  },
  {
    title: 'Data Consumption',
    description:
      'Seamlessly manage data consumption via built-in IAM and facilitate use cases across their whole lifecycle, including providing access to others and curating documentation.',
    icon: icons.dataFlow,
  },
  {
    title: 'Search',
    description:
      'Discover trusted datasets via a simple search that recommends results based on names, descriptions, and tags.',
    icon: icons.search,
  },
  {
    title: 'Data Products',
    description:
      'Create and manage data products at Petabyte-scale via an end-to-end approach, which includes the ingestion, transformation, and semantic data modelling.',
    icon: icons.catalog,
  },
];

const About: React.FC = () => {
  const classes = useStyles();

  return (
    <section className={classes.aboutRoot}>
      <Typography variant={'headline2'}>{localText.howItWorks}</Typography>
      {renderGrid()}
    </section>
  );

  function renderGrid() {
    return (
      <div className={classes.grid}>
        {renderInfoBlocks()}
        <div>
          <img src={infographic} className={classes.infoGraphic} alt={'Infographic'} />
        </div>
      </div>
    );
  }

  function renderInfoBlocks() {
    return (
      <div className={classes.infoBlocksContainer}>
        {infoBlocks.map((infoBlock) => (
          <InfoBlock key={infoBlock.title} {...infoBlock} />
        ))}
      </div>
    );
  }
};

export default About;
