import { createStyles } from '@material-ui/core/styles';
import makeStyles from '../../../util/makeStyles';
import portalTheme from '../../../styles/theme';

export default makeStyles(() =>
  createStyles({
    root: {
      flex: '0 0 auto',
    },

    hoverDefault: {
      '.hoverable:hover &': {
        fill: `${portalTheme.textColors.highlight} !important`,
      },
    },

    hoverPrimaryDark: {
      '.hoverable:hover &': {
        fill: `${portalTheme.colors.primaryDark} !important`,
      },
    },
  })
);
