import makeStyles from '../../../util/makeStyles';
import { createStyles } from '@material-ui/core/styles';

export const textAndIconSpacingNormal = 13;
export const textWidthNormal = 190;
export const logoHeightNormal = 16;

export const textAndIconSpacingSmall = 10;
export const textWidthSmall = 142;
export const logoHeightSmall = 12;

export default makeStyles(() =>
  createStyles({
    logoContainer: {
      width: textAndIconSpacingNormal + textWidthNormal + logoHeightNormal,
      height: logoHeightNormal,
      display: 'flex',
      justifyContent: 'space-between',
    },

    logoContainerSmall: {
      width: textAndIconSpacingSmall + textWidthSmall + logoHeightSmall,
      height: logoHeightSmall,
    },

    textOnly: {
      width: logoHeightNormal,
    },
  })
);
