import makeStyles from '../../../util/makeStyles';
import { createStyles } from '@material-ui/core/styles';
import portalTheme from '../../../styles/theme';

export default makeStyles(() =>
  createStyles({
    infoBlockRoot: {
      display: 'grid',
      gridTemplateColumns: '64px auto',
      gridTemplateRows: 'auto auto',
      gridRowGap: 16,
      [portalTheme.breakpoints.down('xs')]: {
        gridTemplateColumns: '1fr',
        gridTemplateRows: '32px auto auto',
      },
    },

    iconContainer: {
      gridColumn: 1,
      gridRow: '1 / 3',
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      [portalTheme.breakpoints.down('xs')]: {
        gridRow: 1,
        gridColumn: 1,
      },
    },
  })
);
