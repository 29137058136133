import React from 'react';
import useStyles from './Topbar.styles';
import Logo from '../../atoms/Logo/Logo';
import { useScrollYDistance } from '../../../hooks/scrollHooks';
import classNames from 'classnames';
import RequestMoreDetails from '../../molecules/RequestMoreDetails/RequestMoreDetails';

export const pxScrolledForShadow = 25;

const Topbar: React.FC = () => {
  const classes = useStyles();
  const scrollY = useScrollYDistance();

  return (
    <header className={classNames(classes.topbarRoot, scrollY >= pxScrolledForShadow && classes.topbarWithShadow)}>
      <div className={classes.topbarMask} />
      <div className={classes.topbarInner}>
        <Logo hideTextOnMobile />
        {renderRightSection()}
      </div>
    </header>
  );

  function renderRightSection() {
    return (
      <div className={classes.rightSection}>
        <RequestMoreDetails />
      </div>
    );
  }
};

export default Topbar;
