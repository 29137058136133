import React, { useEffect, useState } from 'react';
import useStyles from './BackToTop.styles';
import BackToTopButton from '../../molecules/BackToTopButton/BackToTopButton';
import { useScrollYDistance } from '../../../hooks/scrollHooks';
import classNames from 'classnames';
import { MAIN_ID } from '../../../constants/constants';
import { ScreenSize, useIfScreenSizeAtMost } from '../../../hooks/mediaHooks';

const BackToTop: React.FC = () => {
  const classes = useStyles();
  const [isDisplayed, setIsDisplayed] = useState(false);
  const scrollYDistance = useScrollYDistance();
  const isTablet = useIfScreenSizeAtMost(ScreenSize.sm);

  useEffect(() => {
    const howItWorksOffset = document.getElementById(MAIN_ID)?.getBoundingClientRect().top;

    if (howItWorksOffset) {
      setIsDisplayed(scrollYDistance >= howItWorksOffset);
    }
  }, [scrollYDistance]);

  return (
    <section className={classNames(classes.backToTopRoot, isDisplayed && classes.backToTopRootVisible)}>
      <div className={classNames(classes.backToTopInner, isTablet && classes.backToTopInnerMobile)}>
        <BackToTopButton />
      </div>
    </section>
  );
};

export default BackToTop;
