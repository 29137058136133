import makeStyles from './util/makeStyles';
import { createStyles } from '@material-ui/core';
import portalTheme from './styles/theme';

export default makeStyles(() =>
  createStyles({
    root: {
      backgroundColor: portalTheme.colors.globalBackgroundLight,
      minHeight: '100vh',
      display: 'grid',
      gridTemplateRows: 'auto 1fr auto',
    },
  })
);
