import React from 'react';
import Typography from '../../atoms/Typography/Typography';
import Icon from '../../atoms/Icon/Icon';
import useStyles from './InfoBlock.styles';
import classNames from 'classnames';
import portalTheme from '../../../styles/theme';
import { PathProps } from '../../../statics/icons';

export interface Block {
  title: string;
  description: string;
  icon: PathProps;
}

interface Props extends Block {
  className?: string;
}

const InfoBlock: React.FC<Props> = ({ className, title, description, icon }) => {
  const classes = useStyles();

  return (
    <div className={classNames(className, classes.infoBlockRoot)}>
      <div className={classes.iconContainer}>
        <Icon color={portalTheme.textColors.primary} pathProps={icon} />
      </div>
      <Typography bold>{title}</Typography>
      <Typography>{description}</Typography>
    </div>
  );
};

export default InfoBlock;
