import makeStyles from '../../../util/makeStyles';
import { createStyles } from '@material-ui/core/styles';
import portalTheme from '../../../styles/theme';
import { createRoot } from '../../../styles/shared';

const infographicSidePadding = 48;

export default makeStyles(() =>
  createStyles({
    aboutRoot: createRoot({
      marginTop: 160,
      [portalTheme.breakpoints.down('sm')]: {
        marginTop: 80,
      },
    }),

    grid: {
      marginTop: 64,
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      gridColumnGap: '8%',
      [portalTheme.breakpoints.down('sm')]: {
        marginTop: 40,
        gridTemplateColumns: '1fr',
        gridRowGap: 84,
      },
      [portalTheme.breakpoints.down('xs')]: {
        gridRowGap: 64,
      },
    },

    infoGraphicContainer: {
      display: 'grid',
      placeItems: 'center',
    },

    infoGraphic: {
      display: 'block',
      margin: '0 auto',
      width: `calc(100% - ${infographicSidePadding * 2}px)`,
      height: 'auto',
      maxWidth: 400,
      [portalTheme.breakpoints.down('sm')]: {
        width: '100%',
        maxWidth: 380,
        height: 'auto',
      },
      [portalTheme.breakpoints.down('xs')]: {
        maxWidth: '100%',
      },
    },

    infoBlocksContainer: {
      display: 'grid',
      gridTemplateColumns: '1fr',
      gridRowGap: 48,
      [portalTheme.breakpoints.down('sm')]: {
        gridRowGap: 40,
      },
    },
  })
);
