import React from 'react';
import useStyles from './Cookies.styles';
import Typography from '../../components/atoms/Typography/Typography';
import { localText } from './Cookies.static';

const Cookies: React.FC = () => {
  const classes = useStyles();

  return (
    <div className={classes.cookiesRoot}>
      <Typography variant={'headline2'} className={classes.mainTitle}>
        {localText.cookies}
      </Typography>
      <Typography bold className={classes.heading}>
        {localText.cookieSettings}
      </Typography>
      <Typography className={classes.section}>{localText.cookieUsage}</Typography>
      <Typography bold className={classes.heading}>
        {localText.whatIsACookie}
      </Typography>
      <Typography className={classes.section}>
        {localText.whatIsACookieExplained.map((text, index) => (
          <React.Fragment key={index}>
            {text}
            {index < localText.whatIsACookieExplained.length - 1 && (
              <>
                <br />
                <br />
              </>
            )}
          </React.Fragment>
        ))}
      </Typography>
    </div>
  );
};

export default Cookies;
