import { createStyles } from '@material-ui/core/styles';
import makeStyles from '../../../util/makeStyles';

export default makeStyles(() =>
  createStyles({
    circle: {
      strokeLinecap: 'round',
    },
  })
);
