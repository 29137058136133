import React from 'react';
import { Button } from '@material-ui/core';
import useStyles from './ActionButton.style';
import Typography from '../Typography/Typography';
import Icon from '../Icon/Icon';
import { PathProps } from '../../../statics/icons';
import portalTheme from '../../../styles/theme';

export const ActionButton: React.FC<Props> = ({ onClick, iconPath, name, className }) => {
  const classes = useStyles();

  return (
    <Button disableRipple classes={{ root: classes.buttonRoot }} onClick={onClick} className={className}>
      <Icon pathProps={iconPath} color={portalTheme.textColors.highlight} />
      <Typography variant={'button'} color={portalTheme.textColors.highlight}>
        {name}
      </Typography>
    </Button>
  );
};

interface Props {
  onClick: () => void;
  name: string;
  iconPath: PathProps;
  className?: string;
}

export default ActionButton;
