import { createStyles } from '@material-ui/core/styles';
import makeStyles from '../../../util/makeStyles';
import portalTheme from '../../../styles/theme';

export default makeStyles(() =>
  createStyles({
    buttonRoot: {
      background: 'white',
      borderRadius: 16,
      boxShadow: portalTheme.shadows.strong,
      height: 32,
      textTransform: 'unset',
      whiteSpace: 'nowrap',
      padding: '4px 16px 4px 8px',
      '&:hover': {
        background: 'white',
      },
    },
  })
);
