import { createStyles } from '@material-ui/core';
import { withOpacity } from '../../../util/utilities';
import makeStyles from '../../../util/makeStyles';
import portalTheme from '../../../styles/theme';

export const iconSize = 24;

export default makeStyles(() =>
  createStyles({
    buttonWrapper: {
      display: 'inline-block',
    },

    root: {
      ...portalTheme.fonts.button,
      whiteSpace: 'nowrap',
      borderRadius: '4px',
      textTransform: 'capitalize',
      width: '100%',
    },

    withIcon: {
      paddingLeft: '0 !important', // use icon margin instead
    },

    icon: {
      width: iconSize,
      height: iconSize,
      margin: '0 12px',
      '$textVariant &': {
        margin: '0 4px',
      },
    },

    primaryVariant: {
      minHeight: '32px',
      padding: '4px 22px',
      backgroundColor: portalTheme.colors.primary,
      '&:hover': {
        backgroundColor: portalTheme.colors.primaryDark,
      },
      '&:focus': {
        backgroundColor: portalTheme.colors.primaryDark,
      },
    },

    secondaryVariant: {
      padding: '3px 22px',
      minHeight: '32px',
      backgroundColor: 'transparent',
      border: `1px solid ${portalTheme.colors.primary}`,
      '&:hover': {
        backgroundColor: `${withOpacity(portalTheme.colors.primaryLight, 0.4)}`,
      },
      '&:focus': {
        backgroundColor: `${withOpacity(portalTheme.colors.primaryLight, 0.4)}`,
      },
      '&:disabled': {
        border: `1px solid ${withOpacity(portalTheme.colors.primary, 0.4)}`,
      },
    },

    textVariant: {
      padding: 4,
      '&:hover': {
        backgroundColor: 'transparent',
      },
      '&:focus': {
        backgroundColor: 'transparent',
      },
      '&:hover *': {
        color: `${portalTheme.colors.primaryDark} !important`,
        fill: `${portalTheme.colors.primaryDark} !important`,
      },
      '&:focus *': {
        color: `${portalTheme.colors.primaryDark} !important`,
        fill: `${portalTheme.colors.primaryDark} !important`,
      },
    },

    alertColor: {
      backgroundColor: portalTheme.colors.error,
      '&:hover, &:focus': {
        backgroundColor: portalTheme.colors.errorDark,
      },
    },
  })
);
