import { CDH_EMAIL } from '../../constants/constants';

export const localText = {
  imprint: 'Imprint',
  maintainedBy:
    'This website is maintained by Bayerische Motoren Werke Aktiengesellschaft (Petuelring 130, 80788 München).',
  contact: 'Contact:',
  phone: 'Phone:',
  workingTime: 'Monday - Friday, between 8:00 AM and 6:00 PM (CEST).',
  fax: 'Fax:',
  legalRepresentatives:
    'The legal representatives of BMW AG are the members of the Board of Management (Oliver Zipse, Chairman, Ilka Horstmeier, Frank Weber, Milan Nedeljković, Pieter Nota, Nicolas Peter, Andreas Wendt).',
  supervisoryBoardChairman: 'Chairman of the Supervisory Board: Norbert Reithofer',
  commercialRegister: 'Commercial register: Amtsgericht München. Registergericht: HRB 42243',
  valueAddedTaxIndetification: 'Value-added tax identification no.: DE129273398',
  emailContact: 'Contact: You can contact us via email -',
};

export const localValues = {
  phone: '+49 89 382-0',
  email: CDH_EMAIL,
  fax: '+49 89-382-70-25858',
};
