import React from 'react';
import useStyles from './App.style';
import Topbar from './components/parts/Topbar/Topbar';
import Footer from './components/parts/Footer/Footer';
import BackToTop from './components/parts/BackToTop/BackToTop';
import { Routes, Route } from 'react-router-dom';
import { routes } from './constants/routing';
import { MAIN_ID } from './constants/constants';
import { useScrollToTopOnRouteChange } from './hooks/scrollHooks';

const App: React.FC = () => {
  const classes = useStyles();

  useScrollToTopOnRouteChange();

  return (
    <div className={classes.root}>
      <Topbar />
      <main id={MAIN_ID}>
        <Routes>
          <Route {...routes.imprint} />
          <Route {...routes.cookies} />
          <Route {...routes.index} />
        </Routes>
      </main>
      <Footer />
      <BackToTop />
    </div>
  );
};

export default App;
