import makeStyles from '../../../util/makeStyles';
import { createStyles } from '@material-ui/core/styles';
import portalTheme from '../../../styles/theme';

export default makeStyles(() =>
  createStyles({
    backToTopRoot: {
      position: 'fixed',
      bottom: 0,
      opacity: 0,
      transition: portalTheme.transitions.easeOutSlow,
      width: '100%',
      height: 56,
      visibility: 'hidden',
      transform: 'translateY(100%)',
    },

    backToTopInner: {
      ...portalTheme.sideMargins,
      display: 'flex',
      justifyContent: 'center',
    },

    backToTopInnerMobile: {
      justifyContent: 'center',
    },

    backToTopRootVisible: {
      opacity: 1,
      visibility: 'visible',
      transform: 'translateY(0)',
    },
  })
);
